<template>
    <div class="container">
        <div class="col-wrap header">
            <div class="bg  bg-animation2"></div>
            <div class="wrap-content">
                <Nav></Nav>
                <div class="content">
                    <h1>SUCCESSFUL CASE</h1>
                    <h3>用最契合的传播方式，让每一个案例都成为经典</h3>
                    <p>
                        We are committed to make classic cases with our tailored communication solutions
                    </p>
                </div>
            </div>
        </div>
        <div class="col-wrap detail">
            <div class="wrap-content">
                <div class="content" v-if="detail">
                    <h1 class="title">{{ detail.detail.title }}</h1>
                    <div class="summary" v-html="detail.detail.desc"></div>
                    <div class="wrap" v-html="detail.detail.content"></div>
                </div>
                <div class="tools">
                    <div>
                        <template v-if="detail && detail.prev">
                            <i class="iconfont icon-previous"></i> 上一篇：
                            <a :href="'/case/detail?id=' + detail.prev.id">{{ detail.prev.title }}</a>
                        </template>
                    </div>
                    <section>
                        <a href="javascript:;" @click="backtop">回到顶部</a>
                    </section>
                    <div>
                        <template v-if="detail && detail.next">
                            <i class="iconfont icon-next"></i> 下一篇：
                            <a :href="'/case/detail?id=' + detail.next.id">{{ detail.next.title }}</a>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-wrap">
            <div class="content">
                <Footer></Footer>
            </div>
        </div>
    </div>
</template>
<script>
import Nav from "@/components/pc/nav.vue";
import Footer from "@/components/pc/footer.vue";
import settings from "@/settings";
export default {
    name: "pc-case-detail",
    components: { Nav, Footer },
    data() {
        return { loading: false, resourceBaseUrl: settings.resourceBaseUrl, detail: null };
    },
    mounted() {
        this.loadDetail();
    },
    methods: {
        backtop() {
            var timer = setInterval(function() {
                let osTop = document.documentElement.scrollTop || document.body.scrollTop;
                let ispeed = Math.floor(-osTop / 5);
                document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed;
                this.isTop = true;
                if (osTop === 0) {
                    clearInterval(timer);
                }
            }, 10);
        },
        loadDetail() {
            var id = this.$route.query.id;
            this.$showLoading();
            this.$http
                .get(`/api/case/detail/${id}`)
                .then((res) => {
                    this.detail = res.data;
                })
                .finally(() => {
                    this.$hideLoading();
                });
        },
    },
};
</script>
<style scoped>
@import url("../../../common/css/pc.css");

.detail .content .title {
    margin: 90px 0 40px 0;
    color: #333;
    font-size: 20px;
}
.detail .content .summary {
    color: #0048b1;
    font-size: 15px;
    margin-bottom: 40px;
    line-height: 30px;
}
.detail .content .wrap {
    font-size: 16px;
    color: #333;
    line-height: 30px;
    clear: both;
}

.detail .tools section a {
    width: 152px;
    height: 36px;
    border: 1px #ccc solid;
    border-radius: 19px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #333;
}

.detail .tools div {
    flex: 1;
    color: #333333;
    display: flex;
    align-items: center;
    width: 40%;
}
.detail .tools div i {
    font-size: 15px;
    margin-right: 10px;
}
.detail .tools div i,
.detail .tools div a {
    color: #333;
    font-size: 15px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.detail .tools div a:hover {
    text-decoration: underline;
    color: #0048b1;
}

.detail .tools div:last-child {
    text-align: right;
    justify-content: flex-end;
}

.header .wrap-content {
    display: flex;
    flex-direction: column;
    height: 100%;
}
.header .wrap-content .content {
    flex: 1;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@media only screen and (max-width: 1440px) {
    .header .bg {
        background: url("~@/assets/pc/case/banner-bg.jpg") center bottom;

        height: 500px;
    }
    .header .wrap-content .content h1 {
        font-size: 24px;
        text-align: center;
    }
    .header .wrap-content .content h3 {
        font-size: 30px;
        margin: 15px 0;
        text-align: center;
    }
    .header .wrap-content .content p {
        text-align: center;
        font-size: 20px;
    }
    .detail .content {
        width: 1000px;
        min-height: 300px;
        margin: 0 auto;
    }
    .detail .tools {
        width: 1000px;
        margin: 80px auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

@media only screen and (min-width: 1441px) {
    .header .bg {
        background: url("~@/assets/pc/case/banner-bg.jpg") center bottom;
        height: 760px;
    }
    .header .wrap-content .content h1 {
        font-size: 24px;
        text-align: center;
    }
    .header .wrap-content .content h3 {
        font-size: 30px;
        margin: 15px 0;
        text-align: center;
    }
    .header .wrap-content .content p {
        text-align: center;
        font-size: 20px;
    }
    .detail .content {
        width: 1136px;
        min-height: 300px;
        margin: 0 auto;
    }
    .detail .tools {
        width: 1280px;
        margin: 80px auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
</style>
